import React from 'react';
import PropTypes from 'prop-types';
import romanize from 'romanize';
import HomeLink from '../components/HomeLink';
import ID7Navigation from '../components/ID7Navigation';
import MasqueradeNotice from './MasqueradeNotice';

const romanYear = romanize(new Date().getFullYear());

const masthead = (
  <div className="id7-masthead">
    <div className="id7-masthead-contents">
      <div className="clearfix">
        <div className="id7-logo-column">
          <div className="id7-logo-row">
            <div className="id7-logo">
              <a href="http://warwick.ac.uk" title="Warwick homepage">
                <img src="/assets/lib/id7/images/logo.svg" alt="Warwick" />
              </a>
            </div>
            <nav className="id7-site-links">
              <ul>
                <li><a href="http://warwick.ac.uk/study">Study</a></li>
                <li><a href="http://warwick.ac.uk/research">Research</a></li>
                <li><a href="http://warwick.ac.uk/business">Business</a></li>
                <li><a href="http://warwick.ac.uk/alumni">Alumni</a></li>
                <li><a href="http://warwick.ac.uk/news">News</a></li>
                <li><a href="http://warwick.ac.uk/about">About</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="id7-header-text clearfix">
        <h1>
          <span className="id7-current-site-link">
            <HomeLink to="/">Search</HomeLink>
          </span>
        </h1>
      </div>
    </div>
  </div>
);

const divider = (
  <div className="id7-horizontal-divider">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      width="1130"
      height="40"
      viewBox="0, 0, 1130, 40"
    >
      <path
        d="m 0,0 1030.48, 0 22.8,40 16.96,-31.4 16.96,31.4 22.8,-40 20,0"
        className="divider"
        stroke="#383838"
        fill="none"
      />
    </svg>
  </div>
);

const footer = (
  <footer className="id7-page-footer id7-footer-divider">
    <div className="id7-app-footer">
      <div className="id7-app-footer-content">
        {divider}

        <div className="id7-footer-utility">
          <ul>
            <li>Powered by <a href="http://warwick.ac.uk/services/its/servicessupport/web/search/">
              Warwick Search
            </a></li>
          </ul>
          <ul>
            <li><a href="http://warwick.ac.uk/copyright">© {romanYear}</a></li>
            <li><a href="http://warwick.ac.uk/terms">Terms</a></li>
            <li><a href="http://warwick.ac.uk/privacy">Privacy</a></li>
            <li><a href="http://warwick.ac.uk/cookies">Cookies</a></li>
            <li><a href="http://warwick.ac.uk/accessibility">Accessibility</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

const ID7Layout = ({ utilityBar, children }) => (
  <div>
    <div className="id7-left-border" />
    <div className="id7-fixed-width-container">
      <a className="sr-only sr-only-focusable" href="#main">Skip to main content</a>

      <header className="id7-page-header">
        <MasqueradeNotice />
        <div className="id7-utility-masthead id7-point-1">
          <nav className="id7-utility-bar" id="utility-bar-container">
            {utilityBar}
          </nav>

          {masthead}
        </div>

        <div className="id7-navigation" data-affixheader="false" data-affixnav="false">
          <ID7Navigation />
        </div>
      </header>

      <main className="id7-main-content-area" id="main">
        <header className="id7-main-content-header">
          {divider}
        </header>

        <div className="id7-main-content">
          {children}
        </div>
      </main>

      {footer}
    </div>
    <div className="id7-right-border" />
  </div>
);

export default ID7Layout;

ID7Layout.propTypes = {
  utilityBar: PropTypes.object,
  children: PropTypes.node,
};

ID7Layout.defaultProps = {
  utilityBar: null,
  children: null,
};
